<template>
  <v-card :loading="loading" height="80vh">
    <v-toolbar color="primary" class="rounded-0 text-white" height="80px">
      <v-toolbar-title class="ml-3"
        ><h2><b>Uygulamalar</b> -Bildirimler</h2></v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-btn
        color="error"
        v-if="notifications.length > 0"
        small
        @click="deleteAll()"
      >
        Bildirimleri Sil
      </v-btn>
      <KTTopMenu></KTTopMenu>
    </v-toolbar>
    <v-card-text>
      <v-list rounded max-height="300px">
        <div class="overflow-auto" style="height: 60vh">
          <v-list-item
            :href="notify.data.path"
            v-for="notify in notifications"
            :key="notify.id"
            link
            three-line
            :class="notify.read_at ? 'bg-light-info' : 'bg-light-warning'"
          >
            <template v-slot:default>
              <v-list-item-content>
                <v-list-item-title>
                  <strong>{{ notify.data.subject }}</strong>
                </v-list-item-title>

                <v-list-item-subtitle>
                  <span class="text-primary">{{
                    modifiedCreatedAtForHumans(notify.created_at)
                  }}</span>
                </v-list-item-subtitle>

                <v-list-item-subtitle>
                  {{ notify.data.message }}</v-list-item-subtitle
                >
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon @click="notificationDelete(notify.id)">
                  <v-icon color="red">mdi-delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </template>
            <v-divider :key="n"></v-divider>
          </v-list-item>
        </div>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import KTTopMenu from "@/view/layout/header/TopMenu.vue";
import {
  FETCH_NOTIFICATIONS,
  MARK_AS_READ,
  DELETE_NOTIFICATION,
  DELETE_ALL_NOTIFICATIONS,
} from "@/core/services/store/notifications.module";

import moment from "moment";
moment.locale("tr");

export default defineComponent({
  name: "NotificationList",
  data() {
    return {
      loading: true,
    };
  },
  components: { KTTopMenu },
  async mounted() {
    this.fetchNotifications();
    this.loading = false;
  },
  destroyed() {
    if (this.unreadNotificationsCount > 0) {
      this.markAsRead();
    }
  },
  methods: {
    ...mapActions([
      FETCH_NOTIFICATIONS,
      MARK_AS_READ,
      DELETE_NOTIFICATION,
      DELETE_ALL_NOTIFICATIONS,
    ]),
    async notificationDelete(notificationID) {
      await this.deleteNotification(notificationID);
    },
    async deleteAll() {
      await this.deleteAllNotifications();
    },
    modifiedCreatedAtForHumans(created_at) {
      return moment(created_at).fromNow();
    },
  },
  computed: {
    ...mapGetters(["notifications", "unreadNotificationsCount"]),
  },
});
</script>
